@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.tou {
  // padding: 0 80px;

  // @include laptop {
  //   padding: 0 40px;
  // }

  // @include tablet {
  //   padding: 0 20px;
  // }

  &-inner {
    display: flex;
    gap: 2.5rem;

    @include tablet {
      flex-direction: column;
      gap: 0;
    }
  }

  &-content {
    flex: 1;
    padding-bottom: 2rem;
  }

  h1 {
    font-weight: 600;
    font-size: 2.5rem;
    margin: 0;
    padding-top: 2rem;
    margin-bottom: 2rem;

    @include tablet {
      padding-top: 0;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p,
  i {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;

    @include tablet {
      font-size: 0.875rem;
    }
  }

  a {
    color: #215db0;
    // color: #307ff6;
  }

  strong {
    font-weight: 600;
  }
}
