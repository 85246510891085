@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.search-job {
  padding-bottom: 240px;
  display: flex;
  align-items: center;

  @include responsive-from($md) {
    min-height: calc(100vh - 200px);
  }

  @include tablet {
    order: 999;
    padding-bottom: 40px;
  }

  &-inner {
    // width: 100%;
    // max-width: 1000px;
    // padding: 0 24px;
    // margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 56px;

    @include tablet {
      gap: 24px;
    }
  }

  h6 {
    color: #404040;
    font-size: 56px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.12px;

    span {
      color: #307ff6;
    }

    @include tablet {
      display: none;
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    gap: 24px;

    label {
      color: #6c6c6c;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.4px;

      @include tablet {
        display: none;
      }
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .bp5-tag.bp5-intent-primary {
      background-color: #307ff6;
    }

    .bp5-tag.bp5-round {
      font-size: 16px;
      font-weight: 400;
      padding: 10px 16px;
      gap: 6px;
      letter-spacing: 0.48px;
    }

    @include tablet {
      gap: 12px;

      .bp5-tag.bp5-round {
        font-size: 14px;
        // padding: 8px 16px;
      }
    }
  }

  .searchbox .search-form {
    height: auto;
    align-items: center;

    .searchbox-input-group {
      width: 100%;

      .searchbox-input input::placeholder {
        color: #6c6c6c;
      }
    }

    .submit-searchbox {
      width: 40px;
      height: 40px;
      background-color: #307ff6;
    }

    @include responsive-from(992px) {
      padding: 16px;
      padding-left: 14px;
      border-radius: 999px;

      .submit-searchbox svg {
        width: 20px;
        height: 20px;
      }

      .searchbox-inputs-separator {
        background-color: #6c6c6c;
      }

      .searchbox-input-group .searchbox-input input {
        height: auto;
        font-size: 24px;
      }

      .searchbox-input-group .searchbox-input svg {
        width: 24px;
        height: 24px;
      }
    }

    @include responsive-until(992px) {
      padding-left: 0;
      padding-right: 0;

      .submit-searchbox {
        height: 44px;
        width: 100%;
        padding: 0 16px;
      }

      .search-term-container,
      .search-location-container {
        width: 95%;
      }
    }
  }
}
