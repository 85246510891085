@import 'styles/mixins';

.claim-section {
  overflow-x: hidden;
  background-color: #000;

  @include tablet {
    padding-bottom: 100px;
  }

  &-inner {
    min-height: 600px;
    width: 90%;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    position: relative;
    @include flexbox(column, center, center);

    @include tablet {
      width: 100%;
      min-height: 500px;
    }

    h6 {
      color: #fff;
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 40px;
      z-index: 1;
    }

    form {
      width: 100%;
      max-width: 900px;
      display: flex;
      gap: 30px;
      z-index: 1;

      .bp5-input-group.bp5-large .bp5-input {
        font-family: ploni, sans-serif;
        padding: 12px 24px;
        height: 100%;
        border-radius: 15px;
        font-size: 20px;
      }

      @include tablet {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    transform: translateY(-50%);

    @include tablet {
      top: auto;
      bottom: 0;
      right: -10%;
      transform: scale(3);
      transform-origin: right bottom;
    }
  }
}
