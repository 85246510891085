@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.featured-item {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-left: 80px;
  padding-right: 80px;

  &.reverse {
    flex-direction: row-reverse;
  }

  &-content {
    flex: 1;

    & > h3 {
      font-size: 56px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -1.12px;
      margin-bottom: 16px;
    }

    & > h6 {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > p {
      color: $ColorMidGrey;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  &-action {
    margin-top: 40px;
  }

  &-image {
    width: 400px;
  }

  &-group {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.without-label {
      gap: 16px;

      svg {
        margin: 0;
      }
    }
  }

  &-row {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    svg {
      flex-shrink: 0;
      margin-top: 4px;
    }

    label {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    p {
      color: #000;
      font-size: 18px;
      font-weight: 400;
    }
  }

  @include laptop {
    padding-left: 0;
    padding-right: 0;

    &-content h3 {
      font-size: 48px;
      font-weight: 500;
    }

    &-content p {
      font-size: 20px;
    }
  }

  @include tablet {
    flex-direction: column;
    gap: 24px;

    &.reverse {
      flex-direction: column;
    }

    &-content h3 {
      font-size: 40px;
      margin-bottom: 8px;
    }

    &-action {
      margin-top: 24px;
      text-align: center;
    }

    &-image {
      width: 100%;
      max-width: 60%;
    }
  }

  @include mobile {
    &-group {
      gap: 12px;
    }

    &-group.without-label {
      gap: 8px;
    }

    &-row p {
      font-size: 16px;
    }
  }
}
