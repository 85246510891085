@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.not-found-page {
  padding-top: 80px;
  text-align: center;

  @include mobile {
    padding-top: 40px;
  }

  h1 {
    margin: 0;
    font-size: 200px;
    font-weight: 400;
    line-height: 100%;
    color: #000;

    @include tablet {
      font-size: 140px;
    }
  }

  p {
    font-size: 24px;

    @include tablet {
      font-size: 18px;
    }
  }

  &-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;

    @include tablet {
      bottom: 10%;
    }

    @include mobile {
      bottom: 30%;
    }

    img {
      width: 100%;
    }
  }
}
