html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: ploni, sans-serif !important;
  font-weight: 400;
  color: $ColorDarkGrey;
  line-height: 1.5;

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  a {
    color: $ColorDarkGrey;
  }

  a:hover {
    text-decoration: none;
    color: $ColorPrimaryLight;
  }
}

ul {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
}

p {
  margin-bottom: 0;
}
