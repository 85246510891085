@import 'styles/mixins.scss';

.leaf-step {
  display: flex;
  align-items: center;
  gap: 30px;

  h6 {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  p {
    color: #000;
    font-size: 24px;
  }

  &-content {
    max-width: 300px;
  }

  &-icon {
    position: relative;
    display: flex;

    svg {
      transition: all 100ms ease-in-out;
    }

    svg:last-child {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }

    &:hover svg:last-child {
      opacity: 1;
      visibility: visible;
    }

    &:hover svg:first-child {
      opacity: 0;
      visibility: hidden;
    }
  }

  @include laptop {
    h6 {
      font-size: 28px;
    }

    p {
      font-size: 20px;
    }

    &-icon {
      width: 75px;
    }
  }
}
