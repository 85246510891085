@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.hero-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @include responsive-from($md) {
    gap: 80px;
  }

  &-inner {
    display: flex;
    align-items: flex-start;

    @include tablet {
      flex-direction: column;
      gap: 16px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include tablet {
      gap: 16px;
    }

    & > h1 {
      margin: 0;
      font-size: 100px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -3.12px;

      @include desktop {
        font-size: 80px;
      }

      @include laptop {
        font-size: 64px;
      }

      @include mobile {
        font-size: 48px;
      }
    }

    & > p,
    & > span {
      color: $ColorMidGrey;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.64px;
      max-width: 75%;

      @include laptop {
        font-size: 20px;
      }

      @include tablet {
        font-size: 18px;
      }

      @include mobile {
        max-width: 100%;
      }
    }
  }

  &-image {
    flex-shrink: 0;

    @include laptop {
      max-width: 50%;
    }

    @include tablet {
      max-width: 75%;
      margin: 0 auto;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 60px;

    & > *:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }

    & > *:nth-child(2) {
      grid-row: 2;
      grid-column: 2;
    }

    & > *:nth-child(3) {
      grid-row: 1;
      grid-column: 3;
    }

    & > *:nth-child(4) {
      grid-row: 3;
      grid-column: 1;
    }

    & > *:nth-child(5) {
      grid-row: 3;
      grid-column: 3;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }
  }

  &.isEmployer {
    padding-bottom: 120px;

    @include tablet {
      padding-bottom: 40px;
    }

    .hero-section-inner {
      padding-bottom: 40px;
      align-items: center;

      @include tablet {
        padding-bottom: 24px;
        gap: 40px;
      }
    }

    .hero-section-content {
      gap: 24px;
    }

    .hero-section-content > p {
      color: $ColorMidGrey;
    }

    .hero-section-content > button {
      width: fit-content;

      @include mobile {
        margin: 0 auto;
      }
    }
  }
}
