@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.table-of-contents {
  flex-basis: 25%;
  padding-top: 1.5rem;

  nav {
    position: sticky;
    top: 1.5rem;
    padding: 1rem;
    border-right: 1px solid #dadde1;
    overflow-y: auto;

    ol {
      list-style-position: inside;
    }

    li {
      margin-top: 8px;
    }

    a {
      color: $ColorMidGrey;

      &.active {
        color: $ColorPrimaryLight;
      }

      strong {
        font-size: 16px;
      }
    }
  }

  @include responsive-from($md) {
    .bp5-collapse {
      height: auto;
      overflow-y: visible;

      .bp5-collapse-body[aria-hidden='true'] {
        display: block;
        transform: none !important;
      }
    }

    button.bp5-button {
      display: none;
    }
  }

  @include tablet {
    padding: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);

    nav {
      border: none;
      padding: 0 16px;

      &.isOpen {
        padding: 8px 16px;
        border-top: 1px solid #dadde1;
      }
    }

    button.bp5-button.bp5-minimal {
      font-size: 1rem;
      padding: 8px 16px;
      min-height: 40px;

      &:hover {
        color: $ColorDarkGrey;
      }
    }
  }
}
