@import 'styles/mixins.scss';

.recruiters-section {
  padding-top: 80px;
  background-color: #eeeff2;

  @include mobile {
    padding-top: 40px;
  }

  h6 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 80px;

    span {
      color: #00c8df;
    }

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      margin-bottom: 40px;
      text-align: left;
      max-width: 75%;
    }
  }

  &-bottom {
    padding-top: 120px;

    @include mobile {
      padding-top: 80px;
    }

    img {
      width: 100%;
    }
  }

  &-main {
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: none;
    }

    img {
      width: 15%;
      border-radius: 50%;
    }
  }

  .slick-track {
    display: none;

    @include mobile {
      display: flex;
      gap: 35px;
    }

    img {
      border-radius: 50%;
    }
  }
}
