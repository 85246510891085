@import 'styles/mixins.scss';

.jobs-section {
  padding-top: 80px;
  background-color: #000;

  h6 {
    color: #fff;
    font-size: 56px;
    font-weight: 600;
    line-height: 1.1;
    max-width: 600px;

    @include tablet {
      font-size: 40px;
      font-weight: 500;
    }
  }

  .jobs-section-main {
    display: flex;
    flex-wrap: wrap;
  }

  .jobs-section-action {
    @include flexbox(column, center, center);
    gap: 80px;
    padding-top: 80px;

    button.bp5-button.bp5-minimal {
      color: #00c8df;
      font-size: 20px;
      transition: color 100ms ease-in-out;

      &:hover {
        color: #fff;
      }
    }

    @include tablet {
      gap: 60px;
      padding-top: 60px;
    }

    @include mobile {
      gap: 40px;
      padding-top: 40px;
    }
  }
}

.job-icon {
  width: 20%;
  margin-top: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  span {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.48px;
    transition: color 200ms ease-in-out;
  }

  img {
    width: 200px;
  }

  .gif {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &:hover .gif {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }

  &:hover .image {
    opacity: 0;
    visibility: hidden;
  }

  &:hover span {
    color: #00c8df;
  }

  @include tablet {
    width: 25%;
    margin-top: 40px;

    span {
      font-size: 20px;
    }

    img {
      width: 150px;
    }
  }

  @include mobile {
    width: calc(100% / 3);

    span {
      font-size: 16px;
    }

    img {
      width: 100px;
    }
  }

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
}
