@import 'styles/mixins.scss';

button.bp5-button.marketing-button,
a.bp5-button.marketing-button {
  width: fit-content;
  flex-shrink: 0;
  padding: 12px 24px;
  border: 2px solid #00c8df;
  border-radius: 10px;
  box-shadow: none;
  background-color: #00c8df;
  transition: all 150ms ease-in-out;

  span {
    color: #000;
    font-size: 20px;
    transition: all 150ms ease-in-out;
  }

  &:hover {
    background-color: #000;

    span {
      color: #00c8df;
    }
  }

  @include mobile {
    // font-size: 18px;
    padding: 12px 24px;
  }
}
