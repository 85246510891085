@font-face {
  font-family: 'ploni';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/ploni-regular-aaa.woff2') format('woff2'),
    url('../assets/fonts/ploni-regular-aaa.woff') format('woff');
}

@font-face {
  font-family: 'ploni';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/ploni-demibold-aaa.woff2') format('woff2'),
    url('../assets/fonts/ploni-demibold-aaa.woff') format('woff');
}

@font-face {
  font-family: 'ploni';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/ploni-bold-aaa.woff2') format('woff2'),
    url('../assets/fonts/ploni-bold-aaa.woff') format('woff');
}
