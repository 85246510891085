@import 'normalize.css';
@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';

@import 'variables.scss';
@import 'fonts.scss';
@import 'base.scss';

.main-frame {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
}

.container {
  width: 100%;
  max-width: calc(1280px + 48px);
  padding: 0 24px;
  margin: 0 auto;

  @media screen and (max-width: #{$sm - 1px}) {
    max-width: none;
    padding: 0 16px;
  }
}

button.bp5-button,
a.bp5-button {
  font-family: ploni, sans-serif;
  font-size: 18px;
  font-weight: 500;

  &.bp5-intent-primary {
    min-height: 50px;
    padding: 8px 28px;
    color: #fff;
    border-radius: 60px;
    box-shadow: none;
    background-color: $ColorPrimaryLight;
    transition: all 150ms ease-in-out;

    &:hover {
      background-color: $ColorHover;
    }
  }

  &.bp5-minimal {
    padding: 0;
    color: $ColorDarkGrey;

    &:hover {
      background: none;
      color: $ColorPrimaryLight;
    }
  }
}
