@import 'styles/mixins.scss';

.footer {
  color: #fff;
  background-color: #000;
  padding-top: 24px;
  padding-bottom: 24px;

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;

    @include tablet {
      flex-wrap: wrap;
      gap: 20px;
      padding-bottom: 20px;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    a.disabled {
      cursor: default;
      color: currentColor;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-logo {
    margin-right: 40px;
  }

  &-links {
    display: flex;
    gap: 40px;
    margin-right: 40px;

    @include tablet {
      gap: 24px;
    }

    @include responsive-until(810px) {
      order: 1;
    }

    @include mobile {
      order: 0;
      margin-right: 0;
    }

    a {
      font-size: 16px;
      color: #fff;
    }
  }

  &-socials {
    display: flex;
    align-items: center;
    gap: 24px;

    a {
      display: flex;
      color: #fff;
    }
  }

  &-copyright {
    font-size: 14px;
    color: #fff;
    text-align: center;

    @include responsive-until(810px) {
      font-size: 16px;
      text-align: left;
    }
  }
}
