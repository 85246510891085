@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.companies-section {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #000;

  h6 {
    text-align: center;
    color: $ColorLightGrey;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 56px;
    line-height: 1.1;

    @include laptop {
      font-size: 28px;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    gap: 70px;
  }

  .companies-section-mobile {
    display: none;
  }

  @include tablet {
    padding: 24px;
    padding-bottom: 40px;

    h6 {
      text-align: left;
      color: #fff;
      font-size: 40px;
      max-width: 75%;
    }

    .slick-slider {
      display: none;
    }

    .companies-section-mobile {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 40px;

      img {
        height: 30px;
      }
    }
  }
}
