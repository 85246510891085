@import 'styles/variables.scss';
@import 'styles/mixins.scss';

header {
  padding: 32px 80px 0;
  background-color: $ColorWhite;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  &.header-mobile {
    height: 90px;
    padding: 20px;
    display: none;
  }

  @include laptop {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include tablet {
    &.header-desktop {
      display: none;
    }

    &.header-mobile {
      display: block;
    }

    .header-logo > svg {
      width: 200px;
    }
  }

  .header-logo {
    margin-right: 48px;
  }

  .header-container {
    height: 100%;
    display: flex;
    align-items: center;

    .bp5-tab-list > *:not(:last-child) {
      margin-right: 40px;
    }

    .bp5-tab {
      line-height: 80px;

      a {
        color: #6c6c6c;
        font-size: 18px;
        font-weight: 500;
      }

      a.active {
        color: $ColorPrimaryLight;
        font-weight: 600;
      }
    }

    .bp5-tab-indicator {
      height: 4px;
      background-color: #307ff6;
    }
  }

  .header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 40px;

    .hamburger {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &:hover {
        cursor: pointer;
      }

      .line {
        display: block;
        width: 18px;
        height: 2px;
        background-color: #000;
        transition: all 300ms ease-in-out;
      }

      &.active .line:nth-child(1) {
        transform: rotate(45deg) translate(3px, 5.5px);
      }

      &.active .line:nth-child(2) {
        opacity: 0;
        visibility: hidden;
      }

      &.active .line:nth-child(3) {
        transform: rotate(-45deg) translate(3px, -5.5px);
      }
    }
  }

  .header-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;

    z-index: 9999;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 90px;
    background-color: #000;
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;

    &.active {
      transform: translateX(0);
    }

    & > a {
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;

      &.active {
        color: $ColorPrimaryLight;
      }
    }
  }
}
