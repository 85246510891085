$ColorPrimaryDark: #00074f;
$ColorPrimaryLight: #307ff6;
$ColorError: #ff0000;
$ColorCorrect: #3edf83;
$ColorHover: #002a84;

$ColorDarkGrey: #404040;
$ColorMidGrey: #6c6c6c;
$ColorGrey: #a4a4a4;
$ColorLightGrey: #c7c7c7;
$ColorStroke: #eeeff2;
$ColorInputField: #f8f8f8;
$ColorWhite: #fff;

// breakpoints
$xs: 0;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;
