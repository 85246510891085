@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.privacy-policy {
  // padding: 0 80px;

  // @include laptop {
  //   padding: 0 40px;
  // }

  // @include tablet {
  //   padding: 0 20px;
  // }

  // text-align: justify;

  &-inner {
    display: flex;
    gap: 2.5rem;

    @include tablet {
      flex-direction: column;
      gap: 0;
    }
  }

  &-content {
    flex: 1;
    padding-bottom: 2rem;
  }

  h1 {
    font-weight: 600;
    font-size: 2.5rem;
    margin: 0;
    padding-top: 2rem;
    margin-bottom: 2rem;

    @include tablet {
      padding-top: 0;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p,
  i {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;

    @include tablet {
      font-size: 0.875rem;
    }
  }

  a {
    color: #215db0;
    // color: #307ff6;
  }

  strong {
    font-weight: 600;
  }

  ol.privacy-policy-agenda {
    list-style-position: inside;

    li {
      margin-top: 1rem;
      font-size: 1rem;
    }

    a {
      text-decoration: underline;
    }
  }

  .sub-list {
    padding-left: 1.5rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;

    th,
    td {
      padding: 8px;
      border: 1px solid #dddddd;
      font-size: 1rem;

      @include tablet {
        font-size: 0.875rem;
      }
    }

    th {
      font-weight: 600;
      white-space: nowrap;
    }
  }
}
