@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.employer-page {
  padding-top: 80px;

  @include tablet {
    padding-top: 20px;
  }

  .employer-featured {
    padding-top: 80px;
    padding-bottom: 80px;

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 80px;
    }

    @include tablet {
      padding-top: 24px;
      padding-bottom: 24px;

      &-inner {
        gap: 40px;
      }
    }

    &-bottom {
      padding-top: 120px;

      @include tablet {
        padding-top: 40px;
      }

      img {
        width: 100%;
      }
    }
  }
}
